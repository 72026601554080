import { compose, withProps } from 'vue-compose';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import StaffLookup from './StaffLookup.vue';
import { useRegionStore } from 'store/region/useRegionStore';
import { createStore } from 'store/index';
import { wrapComponent } from 'shared/apollo-hoc';
import { GetStaffQuery, useGetStaffQuery } from 'shared/generated/graphql-types';
import { computed } from 'vue';
import { ArrayElement } from 'shared/util/types';

export type Staff = ArrayElement<GetStaffQuery['staff']>['Staff']

const store = createStore();
const { getCurrentRegion } = useRegionStore(store);

interface StateToProps {
  regionId: number | null;
}

interface StaffByRegionProps {
  staff: Staff[];
  loading: boolean;
}

export const staffLookupEnhancer = wrapComponent<StateToProps, StaffByRegionProps>((props) => {
  const { loading, result } = useGetStaffQuery(
    computed(() => ({ filter: { regionId: props.regionId, active: true } })),
    { enabled: computed(() => !!props.regionId) }
  );

  return computed(() => ({
    loading: loading.value,
    staff: orderBy(
      uniqBy((result.value?.staff || []).map((x) => x.Staff), 'staffID'),
      ['lastName'],
      ['desc']
    )
  }));
});

export default compose(
  compose(
    withProps<StateToProps, StateToProps>((props) => ({
      regionId: props.regionId || getCurrentRegion()
    }))
  ),
  staffLookupEnhancer
)(StaffLookup);
