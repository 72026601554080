const jsu = 'https://res.cloudinary.com/orthodox-union/image/upload/f_auto,q_auto/v1693308298/JSU/logos/JSU_logo_in_blue_square_160x160.png';
const lnl = 'https://res.cloudinary.com/ncsy/image/upload/f_auto/logos/latte.jpg';
const other = 'https://res.cloudinary.com/ncsy/image/upload/f_auto/logos/other.jpg';
const fridayNightLights = 'https://res.cloudinary.com/ncsy/image/upload/f_auto/logos/friday_night_lights.jpg';
const shabbatOneg = 'https://res.cloudinary.com/ncsy/image/upload/f_auto/logos/shabbat_oneg.jpg';
const shabbatMeal = 'https://res.cloudinary.com/ncsy/image/upload/f_auto/logos/shabbat_meal.jpg';
const shabbaton = 'https://res.cloudinary.com/ncsy/image/upload/f_auto/logos/shabbaton.jpg';
const tya = 'https://res.cloudinary.com/ncsy/image/upload/v1542541396/TYA_jpeg_b8vmdr.jpg';
const bgCubed = 'https://res.cloudinary.com/orthodox-union/image/upload/f_auto,q_auto/v1686051841/Logos/vector/bg-cubed-round-logo-full-color-rgb-900px-w-72ppi.png';
const pivot = 'https://res.cloudinary.com/ouwp/images/v1721879840/NCSY/MAIN/PIVOT-logo-square/PIVOT-logo-square.jpeg?_i=AA';
const bmb = 'https://res.cloudinary.com/orthodox-union/image/upload/v1726741537/bmblogo_zun6l8.png';

export {
  jsu,
  lnl,
  other,
  fridayNightLights,
  shabbatOneg,
  shabbatMeal,
  shabbaton,
  tya,
  bgCubed,
  pivot,
  bmb
};
