import { Steps } from "../shared/MultistepForm";
import {
  jsu as jsuThumbnail,
  lnl as lnlThumbnail,
  other as otherThumbnail,
  shabbaton as shabbatonThumbnail,
  shabbatOneg as shabbatOnegThumbnail,
  shabbatMeal as shabbatMealThumbnail,
  fridayNightLights as fridayNightLightsThumbnail,
  bgCubed as bgCubedThumbnail,
  tya as tyaThumbnail,
  pivot as pivotThumbnail,
  bmb as bmbThumbnail
} from "../shared/DefaultEventImages";
import DateTimezone from "date-timezone";
import { LookupTeen, toCompactTeens } from "shared/components/TeenLookup/utils";
import config from "../../../../../config";
import { BaseUrl, EventForEditFragment as Event, GetEventForEditQuery } from "shared/generated/graphql-types";
import { ArrayElement } from "shared/util/types";

type Attendance = ArrayElement<Event['Attendances']>
type Registration = ArrayElement<Event['Registrations']>
type Staff = NonNullable<GetEventForEditQuery['event']['Advisor']>
type AdvisorRegion = ArrayElement<NonNullable<Staff>['AdvisorRegions']>
type EventTicket = ArrayElement<GetEventForEditQuery['event']['EventTickets']>

const shabbatTypeThumbnailsMap: { [x: number]: string } = {
  295: shabbatOnegThumbnail,
  304: shabbatMealThumbnail,
};

function convertEventTickets(eventTickets: EventTicket[]) {
  return (eventTickets || []).map((et) => {
    const startDate = et.startDate ? new Date(et.startDate) : null;
    const endDate = et.endDate ? new Date(et.endDate) : null;
    return {
      ...et,
      startDate: startDate
        ? {
            year: startDate.getUTCFullYear(),
            month: startDate.getUTCMonth() + 1,
            day: startDate.getUTCDate(),
          }
        : null,
      endDate: endDate
        ? {
            year: endDate.getUTCFullYear(),
            month: endDate.getUTCMonth() + 1,
            day: endDate.getUTCDate(),
          }
        : null,
    };
  });
}

function getEventImage(event: Event, defaultImage: string): string {
  if (event.hasImage) {
    return `${config.graphql}/eventimage/${event.eventId}`;
  } else {
    return defaultImage;
  }
}

export default function getStepData(
  eventType: string,
  event: Event
): Steps | null {
  if (!event) {
    return null;
  }

  DateTimezone.setGlobalTimezone(event.TimeZone);
  const startDate = new DateTimezone.DateTimezone(event.startDate);
  const endDate = new DateTimezone.DateTimezone(event.endDate);
  const startHour = startDate.getHours();
  const endHour = endDate.getHours();
  let currentStaff:
    | (AdvisorRegion & {
        Staff: Pick<Staff, "firstName" | "lastName" | "__typename" | "staffID">;
      })
    | null = null;

  if (event.Advisor) {
    const flipped = event.Advisor.AdvisorRegions.filter((a) => a.active).map(
      (ar) => {
        const { AdvisorRegions, ...staff } = event.Advisor!;
        return { ...ar, Staff: staff };
      }
    );
    currentStaff =
      flipped.find((ar) => ar.Region.regionId === event.regionId) || null;
    if (event.chapter) {
      currentStaff =
        flipped.find((ar) =>
          ar.Chapter ? ar.Chapter.chapterId === event.chapter!.chapterId : false
        ) || currentStaff;
    }
  }

  const details = {
    active: true,
    completed: true,
    data: {
      name: event.eventName,
      TimeZone: event.TimeZone,
      subtitle: event.subtitle,
      isVirtual: event.isVirtual,
      streamSchedule: event.isVirtual && event.zoomMeetingId,
      streamLink: event.streamLink,
      zoomMeetingId: event.zoomMeetingId,
      zoomPasscode: event.zoomPasscode,
      zoomPasscodeHashed: event.zoomPasscodeHashed,
      Address: {
        address: event.address1,
        city: event.city,
        state: event.state,
        zipCode: event.zip,
      },
      FirstEvent: {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
      },
      LastEvent: {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate(),
      },
      StartTime: {
        hours: startHour <= 12 ? startHour : startHour - 12,
        minutes: startDate.getMinutes().toString().padStart(2, "0"),
        ampm: startHour < 12 ? "AM" : "PM",
      },
      EndTime: {
        hours: endHour <= 12 ? endHour : endHour - 12,
        minutes: endDate.getMinutes().toString().padStart(2, "0"),
        ampm: endHour < 12 ? "AM" : "PM",
      },
      EventTags: event.EventTags,
      TagsUpdated: event.EventTags?.map(t => t?.tagName),
    },
  };

  const eventSubType = {
    active: true,
    completed: true,
    data: {
      subType: {
        ...event.EventSubType,
      },
    },
  };

  const staffAndChapter = {
    active: true,
    completed: true,
    data: {
      chapter: event.chapter,
      primaryStaff: currentStaff ? currentStaff.Staff : currentStaff,
      staff: event.EventStaff ? event.EventStaff.map((es) => es.Staff) : [],
      teens: event.registrationRequired
        ? event.Registrations
          ? toCompactTeens(
              event.Registrations.map(
                (r: Registration) => r.Teen
              ) as LookupTeen[]
            )
          : []
        : event.Attendances
        ? toCompactTeens(
            event.Attendances.map(
              (a: Attendance) => a.Teen
            ) as LookupTeen[]
          )
        : [],
    },
  };

  const options = {
    active: true,
    completed: true,
    data: {
      eventTrack: event.EventTrack,
      hideFromWebAndMobile: event.internal,
      NotificationEmails: event.NotificationEmails,
      isTYAEvent: event.baseUrl === BaseUrl.TorahYouthGroup,
      isBGCubedEvent: event.baseUrl === BaseUrl.BgCubed,
      isBMBEvent: event.baseUrl === BaseUrl.Bmb,
      registrationRequired: event.registrationRequired,
      capacity: event.capacity,
      cost: event.cost,
    },
  };

  let RegistrationCloseDate = null;
  let RegistrationCloseTime = null;
  if (event.registrationRequired && event.registrationCloseDate) {
    const registrationCloseDate = new DateTimezone.DateTimezone(
      event.registrationCloseDate
    );
    const registrationCloseHour = registrationCloseDate.getHours();
    RegistrationCloseDate = {
      year: registrationCloseDate.getFullYear(),
      month: registrationCloseDate.getMonth() + 1,
      day: registrationCloseDate.getDate(),
    };
    RegistrationCloseTime = {
      hours:
        registrationCloseHour <= 12
          ? registrationCloseHour
          : registrationCloseHour - 12,
      minutes: registrationCloseDate.getMinutes().toString().padStart(2, "0"),
      ampm: registrationCloseHour < 12 ? "AM" : "PM",
    };
  }

  const registrationAndTickets = {
    active: true,
    completed: true,
    data: {
      registrationRequired: event.registrationRequired,
      registrationAbsoluteRequired:
        event.EventSubType.type === "Shabbaton" &&
        !["Friday Night Lights", "Shabbat Shabang"].includes(
          event.EventSubType.description!
        ),
      requiresParentalApproval: event.requiresParentalApproval,
      ncsyWaiverRequired: event.ncsyWaiverRequired,
      additionalEventItems: event.AdditionalEventItems,
      tickets: convertEventTickets(event.EventTickets),
      requiredManualRegistrationApproval:
        event.requireManualRegistrationApproval,
      canRequestScholarshipOnline: event.scholarshipAvailable,
      needHousing: event.housingFlag,
      canRegisterOnline: event.registrationEnabled,
      partialPayments: event.partialPaymentsAllowed,
      splitPayments: event.splitPaymentsAllowed,
      splitPaymentsMonths: event.splitPaymentsMonths
        ? Number(event.splitPaymentsMonths)
        : null,
      RegistrationCloseDate,
      RegistrationCloseTime,
    },
  };

  let defaultThumbnail = otherThumbnail;

  switch (eventType) {
    case "JSU":
      return {
        school: {
          data: {
            school: event.School,
          },
        },
        details,
        staffAndChapter,
        thumbnailAndDescription: {
          active: true,
          completed: true,
          data: {
            thumbnail: getEventImage(event, jsuThumbnail),
            description: event.description,
          },
        },
        eventSubType,
        options,
      };
    case "LNL":
      return {
        details,
        school: {
          data: {
            school: event.School,
          },
        },
        staffAndChapter,
        thumbnailAndDescription: {
          active: true,
          completed: true,
          data: {
            thumbnail: getEventImage(event, lnlThumbnail),
            description: event.description,
          },
        },
        eventSubType,
        options,
      };
    case "Other":
      if (options.data.eventTrack) {
        if (options.data.eventTrack.id === 24) {
          defaultThumbnail = jsuThumbnail;
        } else if (options.data.eventTrack.id === 31) {
          defaultThumbnail = pivotThumbnail;
        }
      } else if (options.data.isTYAEvent) {
        defaultThumbnail = tyaThumbnail;
      } else if (options.data.isBGCubedEvent) {
        defaultThumbnail = bgCubedThumbnail;
      } else if (options.data.isBMBEvent) {
        defaultThumbnail = bmbThumbnail;
      }
      return {
        eventSubType,
        details,
        school: {
          data: {
            school: event.School,
          },
        },
        staffAndChapter,
        thumbnailAndDescription: {
          active: true,
          completed: true,
          data: {
            thumbnail: getEventImage(event, defaultThumbnail),
            description: event.description,
          },
        },
        registrationAndTickets,
        options,
      };
    case "ShabbatHoliday":
      return {
        eventSubType,
        staffAndChapter,
        details,
        school: {
          data: {
            school: event.School,
          },
        },
        thumbnailAndDescription: {
          active: true,
          completed: true,
          data: {
            thumbnail:
              getEventImage(
                event,
                shabbatTypeThumbnailsMap[event.EventSubTypeID]
              ) || getEventImage(event, otherThumbnail),
            description: event.description,
          },
        },
        registrationAndTickets,
        options,
      };
    case "Shabbaton":
      return {
        eventSubType,
        staffAndChapter,
        details,
        school: {
          data: {
            school: event.School,
          },
        },
        thumbnailAndDescription: {
          active: true,
          completed: true,
          data: {
            thumbnail:
              event.EventSubTypeID === 292
                ? getEventImage(event, fridayNightLightsThumbnail)
                : getEventImage(event, shabbatonThumbnail),
            description: event.description,
          },
        },
        registrationAndTickets,
        options,
      };
  }
  return null;
}
