import SideBar from './SideBar.vue';
import { compose } from 'vue-compose';
import { Props } from './types';
import { wrapComponent } from 'shared/apollo-hoc';
import { b64toBlob } from 'shared/util';
import {
  GetEventDashboardDataDocument,
  GetEventDashboardDataQuery,
  useDownloadLiabilitiesLazyQuery,
  useDownloadWaiversLazyQuery,
  useRecordImpressionsMutation
} from 'shared/generated/graphql-types';
import { computed } from 'vue';
import FileSaver from 'file-saver';

const recordImpressionsEnhancer = wrapComponent<
  Props,
  Pick<Props, 'recordImpressions' | 'recordingImpression'>
>((props) => {
  const { loading, mutate } = useRecordImpressionsMutation();

  return computed(() => ({
    recordImpressions: ({ count, notes = '' }, id) => {
      const {
        user: { staffID: staffId, fullName },
        eventId
      } = props;
      return mutate(
        { eventId, count, notes, staffId },
        {
          optimisticResponse: {
            recordImpressions: {
              id,
              eventId,
              count,
              notes,
              staffId,
              Staff: {
                staffID: staffId,
                fullName,
                __typename: 'Staff'
              },
              __typename: 'EventImpression'
            }
          },
          update: (proxy, { data: updateData }) => {
            const data = proxy.readQuery<GetEventDashboardDataQuery>({
              query: GetEventDashboardDataDocument,
              variables: { eventId }
            });
            if (data && !data.event.EventImpressions.length && updateData?.recordImpressions) {
              const impressions = data.event.EventImpressions.slice();
              impressions.push(updateData.recordImpressions);

              proxy.writeQuery<GetEventDashboardDataQuery>({
                query: GetEventDashboardDataDocument,
                variables: { eventId },
                data: {
                  event: {
                    ...data.event,
                    EventImpressions: impressions
                  }
                }
              });
            }
          }
        }
      );
    },
    recordingImpression: loading.value
  }));
});

const downloadWaiversEnhancer = wrapComponent<
  Props,
  Pick<Props, 'downloadWaivers' | 'downloadingWaivers'>
>((props) => {
  const { document, loading, load, onResult, refetch } = useDownloadWaiversLazyQuery({
    eventId: props.eventId
  });

  function fetchOrRefetch() {
    load(document.value, { eventId: props.eventId }) || refetch({ eventId: props.eventId });
  }

  onResult((result) => {
    if (result.data?.downloadWaivers) {
      const { data, contentType, fileName } = result.data.downloadWaivers;
      FileSaver.saveAs(b64toBlob(data!, contentType!, `data:${contentType};base64,`), fileName!);
    }
  });

  return computed(() => ({
    downloadingWaivers: loading.value,
    downloadWaivers: () => {
      fetchOrRefetch();
    }
  }));
});

const downloadLiabilitiesEnhancer = wrapComponent<
  Props,
  Pick<Props, 'downloadLiabilities' | 'downloadingLiabilities'>
>((props) => {
  const { document, loading, load, onResult, refetch } = useDownloadLiabilitiesLazyQuery({
    eventId: props.eventId,
    registrationIds: []
  });

  function fetchOrRefetch() {
    load(document.value, { eventId: props.eventId, registrationIds: [] }) || refetch({ eventId: props.eventId, registrationIds: [] });
  }

  onResult((result) => {
    if (result.data?.downloadLiabilities) {
      const { data, contentType, fileName } = result.data.downloadLiabilities;
      FileSaver.saveAs(b64toBlob(data!, contentType!, `data:${contentType};base64,`), fileName!);
    }
  });

  return computed(() => ({
    downloadingLiabilities: loading.value,
    downloadLiabilities: () => {
      fetchOrRefetch();
    }
  }));
});

export default compose(recordImpressionsEnhancer, downloadWaiversEnhancer, downloadLiabilitiesEnhancer)(SideBar);
