import { computed, defineComponent, h, PropType, ref } from 'vue';
import sortBy from 'lodash/sortBy';
import toLower from 'lodash/toLower';
import {
  Props,
  Registration,
  RegistrationFilter,
  RegistrationPredicate,
} from './types';
import {
  PaymentType,
  SortType,
  StatusType,
  useAddPaymentMutation,
} from 'shared/generated/graphql-types';
import { Filters, ScholarshipStatus } from '../types';

const predicates: { [K in keyof Filters]: RegistrationFilter<Filters[K]> } = {
  gender: (value) => (r) => r.Teen.Person.gender === value,
  paymentStatus: (value) => (r) => {
    if (toLower(value as string) === toLower('NoPaymentsMade')) {
      return r.Payments.length === 0;
    }
    return r.paymentStatuses.map(toLower).includes(toLower(value || ''));
  },
  registrationStatus: (value) => (r) =>
    toLower(r.status) === toLower(value || ''),
  scholarship: (value) => (r) => {
    const scholarshipStatus: { [key: string]: string } = Object.keys(
      ScholarshipStatus
    ).reduce((collection, key) => ({ ...collection, [key]: toLower(key) }), {});
    switch (toLower(value || '')) {
      case scholarshipStatus.Approved:
        return r.scholarshipGrant !== null && r.scholarshipGrant > 0;
      case scholarshipStatus.Denied:
        return (
          r.scholarshipRequest !== null &&
          r.scholarshipRequest > 0 &&
          r.scholarshipGrant === 0
        );
      case scholarshipStatus.Pending:
        return (
          r.scholarshipRequest !== null &&
          r.scholarshipRequest > 0 &&
          r.scholarshipGrant === null
        );
      default:
        return false;
    }
  },
};

const sortByFunctions: { [k: string]: (r: Registration) => any } = {
  [SortType.Balance]: (r) => r.balance,
  [SortType.Id]: (r) => r.registrationID,
  [SortType.Name]: (r) => r.Teen.Person.lastName,
  [SortType.RegistrationDate]: (r) => r.registrationDate,
};

export default defineComponent({
  props: {
    registrations: {
      type: Array as PropType<Props['registrations']>,
      required: true,
    },
    filters: { type: Object as PropType<Props['filters']>, required: true },
    event: { type: Object as PropType<Props['event']>, required: true },
    sortBy: { type: String as PropType<Props['sortBy']>, default: null },
    sortDescending: { type: Boolean, required: true },
    limit: { type: Number, required: true },
  },
  setup(props, { slots }) {
    const currentPage = ref(1);
    const processingPayment = ref(false);

    const paymentPayload = computed(() => {
      return {
        amount: 0,
        type: PaymentType.Cash,
        CCType: null,
        note: '',
        authorizationToken: null,
        checkNumber: null,
        source: null,
        paymentDate: new Date(),
        suppressEmail: false,
      };
    });

    const filteredRegistrations = computed(() => {
      const filters: Array<RegistrationPredicate> = [];

      (Object.entries(props.filters) as [keyof Filters, Filters[keyof Filters]][]).map(([key, value]) => {
        if (value !== null) {
            const predicate = predicates[key] as RegistrationFilter<Filters[keyof Filters]>;
            filters.push(predicate(value, props.event));
          }
        }
      );

      const filteredRegistrations = filters.reduce(
        (registrations, filter) => registrations.filter(filter),
        props.registrations
      );

      if (props.sortBy) {
        const func = sortByFunctions[props.sortBy];

        const registrations = sortBy<Registration>(filteredRegistrations, func);

        if (props.sortDescending) registrations.reverse();

        return registrations;
      }

      if (props.filters.paymentStatus) {
        return filteredRegistrations;
      } else {
        return filteredRegistrations.filter(
          (r) => toLower(r.status) !== toLower(StatusType.Cancelled)
        );
      }
    });

    const paginatedRegistrations = computed(() => {
      return filteredRegistrations.value.slice(
        (currentPage.value - 1) * props.limit,
        (currentPage.value - 1) * props.limit + props.limit
      );
    });

    const total = computed(() => {
      return filteredRegistrations.value.length;
    });

    const totalPages = computed(() => {
      if (props.limit === total.value) return total.value / props.limit;
      return Math.ceil(total.value / props.limit);
    });

    const loading = computed(() => {
      return processingPayment.value;
    });

    async function addPayment(registrationID: number, amount: number) {
      const { mutate } = useAddPaymentMutation();

      processingPayment.value = true;

      try {
        const result = await mutate({
          input: { ...paymentPayload.value, amount: Number(amount) },
          registrationId: registrationID
        });
        return result;
      } finally {
        processingPayment.value = false;
      }
    }

    function pageChange(page: number) {
      currentPage.value = page;
    }

    return () => {
      const vNode =
        (slots.default &&
          slots.default({
            paginatedRegistrations: paginatedRegistrations.value,
            currentPage: currentPage.value,
            totalPages: totalPages.value,
            total: total.value,
            limit: props.limit,
            pageChange,
            addPayment,
            loading: loading.value,
          })) ||
        [];

      return h('div', vNode);
    };
  }
});
