var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('section',[_c('div',{staticClass:"form-row"},[_vm._v(" ParentID: "),_c('strong',[_vm._v(_vm._s(_vm.parent.personId))])]),_c('div',{staticClass:"form-row"},[_c('div',[_c('label',[_vm._v("Name")]),_c(_setup.AutoSaveField,{attrs:{"name":"fullName","entity":_setup.parentCopy,"transform":(c) => ({
              value: c,
              info: {
                handler: _setup.updatePersonHandler,
                data: { personId: _setup.personId, fieldName: _setup.ParentFieldsEnum.fullName }
              }
            }),"validators":[
            {
              name: 'fullName',
              validator: (name) => !name || _setup.validateName(name)
            }
          ]},on:{"update:entity":function($event){_setup.parentCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.UIInput,_vm._g(_vm._b({attrs:{"data-test":"nameField","type":"text"}},'UIInput',$props,false),$listeners)),(hasError('fullName'))?_c('span',{staticClass:"error-label"},[_vm._v("Please enter the first and last name")]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"form-row"},[_c('div',[_c('label',[_vm._v("Olami ID")]),_c(_setup.AutoSaveField,{attrs:{"name":"olamiId","entity":_setup.parentCopy,"transform":(c) => ({
              value: c,
              info: {
                handler: _setup.updatePersonHandler,
                data: { personId: _setup.personId, fieldName: _setup.ParentFieldsEnum.olamiId }
              }
            })},on:{"update:entity":function($event){_setup.parentCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.UIInput,_vm._g(_vm._b({attrs:{"type":"text","placeholder":"Olami ID"}},'UIInput',$props,false),$listeners))]}}])})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"open-side__toogle",on:{"click":_setup.viewProfile}},[_vm._v("View more info")])]),_c(_setup.Divider,{attrs:{"marginTop":4,"marginBottom":4,"lineStyle":"dashed"}}),_c('div',{staticClass:"form-row smaller"},[_c('div',{staticClass:"position-relative"},[_c('label',[_vm._v("Email Address")]),_c(_setup.AutoSaveField,{attrs:{"name":"email","entity":_setup.parentCopy,"transform":(c) => ({ value: c, info: _setup.emailData })},on:{"update:entity":function($event){_setup.parentCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.EmailInput,_vm._g(_vm._b({attrs:{"ownerType":"parent","required":true,"name":"email","placeholder":"Email","ownerId":_setup.personId,"showErrors":true,"left":true},on:{"associate":_vm.associateHandler}},'EmailInput',$props,false),$listeners))]}}])})],1)]),_c('div',{staticClass:"form-row"},[_c('div',[_c('label',[_vm._v("Home Phone Number")]),_c(_setup.AutoSaveField,{attrs:{"name":"landline","entity":_setup.parentCopy,"toForm":(c) => (_setup.validateNumber(c) ? _setup.phoneFormat(c, _vm.parent.country) : ''),"toEntity":_setup.phoneInternational,"transform":(c) => ({ value: c, info: _setup.landlineData }),"validators":[
            {
              name: 'validateNumber',
              validator: (value) => _setup.validateNumber(value, true)
            }
          ]},on:{"update:entity":function($event){_setup.parentCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.PhoneInput,_vm._g(_vm._b({attrs:{"type":"tel","name":"landline","check-is-duplicate":true,"ownerType":_setup.PersonType.Parent,"ownerId":_setup.personId,"showErrors":true,"left":false,"setDuplicateParentId":_vm.setDuplicateParentId,"setTeenId":_vm.setTeenId,"setCommunityMemberId":_vm.setCommunityMemberId,"closeSidePanel":_vm.closeSidePanel}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Home Phone Number must be a number")]):_vm._e()]}}])})],1),_c('div',[_c('label',[_vm._v("Cellphone Number")]),_c(_setup.AutoSaveField,{attrs:{"name":"cellphone","entity":_setup.parentCopy,"toForm":(c) => (_setup.validateNumber(c) ? _setup.phoneFormat(c, _vm.parent.country) : ''),"toEntity":_setup.phoneInternational,"transform":(c) => ({ value: c, info: _setup.cellphoneData }),"validators":[
            {
              name: 'validateNumber',
              validator: (value) => _setup.validateNumber(value, true)
            }
          ]},on:{"update:entity":function($event){_setup.parentCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners, hasError }){return [_c(_setup.PhoneInput,_vm._g(_vm._b({attrs:{"type":"tel","name":"cellphone","check-is-duplicate":true,"ownerType":_setup.PersonType.Parent,"placeholder":"Cell phone number","ownerId":_setup.personId,"showErrors":true,"left":false,"setDuplicateParentId":_vm.setDuplicateParentId,"setTeenId":_vm.setTeenId,"setCommunityMemberId":_vm.setCommunityMemberId,"closeSidePanel":_vm.closeSidePanel}},'PhoneInput',$props,false),$listeners)),(hasError('validateNumber'))?_c('span',{staticClass:"error-label"},[_vm._v("Cellphone Number must be a number")]):_vm._e()]}}])})],1)])],1),_c('section',[_c('div',[_c(_setup.AutoSaveField,{attrs:{"name":"address","entity":_setup.parentCopy,"transform":(c) => ({ value: JSON.stringify(c), info: _setup.addressData })},on:{"update:entity":function($event){_setup.parentCopy=$event},"save":_setup.createOrUpdate},scopedSlots:_vm._u([{key:"default",fn:function({ $props, $listeners }){return [_c(_setup.Address,_vm._g(_vm._b({key:JSON.stringify(_setup.parentCopy.address || {}),attrs:{"defaultCountry":_setup.country},scopedSlots:_vm._u([{key:"default",fn:function({ countries, setCountry, setAddress, country, address, lookupAddress }){return [_c('div',{staticClass:"form-row smaller"},[_c('label',[_vm._v("Country")]),_c('select',{domProps:{"value":country},on:{"change":(e) => {
                      setCountry(e.currentTarget.value);
                      lookupAddress();
                    }}},_vm._l((countries),function(country){return _c('option',{key:country.code,domProps:{"value":country.code}},[_vm._v(" "+_vm._s(country.name)+" ")])}),0)]),_c('div',{staticClass:"form-row smaller"},[_c(_setup.UITextarea,{attrs:{"placeholder":"Address","value":address},on:{"blur":lookupAddress,"input":function($event){return setAddress($event)}}})],1)]}}],null,true)},'Address',$props,false),$listeners))]}}])})],1)]),_c(_setup.Divider,{attrs:{"marginTop":4,"marginBottom":4,"lineStyle":"dashed"}}),_c('section',[_c('div',[_c(_setup.UIButton,{on:{"click":function($event){_setup.showPasswordModal = true;
          _vm.changePasswordPersonId = _setup.personId;}}},[_vm._v("Change Password")])],1),_c(_setup.Modal,{staticClass:"modal--password",attrs:{"show":_setup.showPasswordModal,"requestClose":() => {
          _setup.showPasswordModal = false;
          _setup.password = null;
        }}},[_c('div',{staticClass:"flex"},[_c('h1',[_vm._v("Reset Password")]),_vm._v("     "),(_vm.changingPassword)?_c('span',{staticClass:"spinner"},[_c('span',{staticClass:"icon"})]):_vm._e()]),_c(_setup.UIPassword,{model:{value:(_setup.password),callback:function ($$v) {_setup.password=$$v},expression:"password"}}),_c('br'),_c(_setup.UIButton,{on:{"click":_setup.passwordHandler}},[_vm._v(_vm._s(_vm.changingPassword ? 'Reseting...' : 'Reset'))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }