import { render, staticRenderFns } from "./Summary.vue?vue&type=template&id=2dacf7e9&scoped=true&"
import script from "./Summary.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Summary.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Summary.vue?vue&type=style&index=0&id=2dacf7e9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dacf7e9",
  null
  
)

export default component.exports