
import { computed, defineComponent, h, PropType, ref, watch } from 'vue';
import { useApolloClient } from '@vue/apollo-composable';
import tz from 'timezone/loaded';
import ScheduleEvent from './ScheduleEvent.vue';
import { Region } from 'shared/types';
import { getZoomTimeZone } from 'shared/util';
import { ArrayElement } from 'shared/util/types';
import {
  CreateZoomMeetingDocument,
  CreateZoomMeetingMutation,
  CreateZoomMeetingMutationVariables,
  EventInput,
  GetEventSubTypesQuery,
  MeQuery,
  RecurrenceType,
  ScheduleEventDocument,
  ScheduleEventMutation,
  ScheduleEventMutationVariables,
  ScheduleQueryDocument,
  ScheduleQueryMutation,
  ScheduleQueryMutationVariables,
  SendPushNotificationDocument,
  SendPushNotificationMutation,
  SendPushNotificationMutationVariables,
  UpdateZoomMeetingDocument,
  UpdateZoomMeetingMutation
} from 'shared/generated/graphql-types';
import {
  jsu as jsuThumbnail,
  lnl as lnlThumbnail,
  other as otherThumbnail,
  shabbaton as shabbatonThumbnail
} from '../shared/DefaultEventImages';
import { Steps } from '../shared/MultistepForm';
import { EventScheduleInput, FormState } from '../shared/types';
import { calculateEventDuration } from '../shared/util';

type Me = MeQuery['me'];
type AdvisorRegions = ArrayElement<Me['AdvisorRegions']>;
type GetEventSubTypes = GetEventSubTypesQuery['eventSubTypes'];
type Event = ScheduleEventMutation['scheduleEvent'];
type Series = NonNullable<ScheduleQueryMutation['scheduleSeries']>;

function getDefaultStepData(
  eventType: string,
  user: AdvisorRegions & { Staff: Me },
  region: Region
): Steps | null {
  switch (eventType) {
    case 'JSU':
      return {
        school: {},
        details: {
          data: {
            TimeZone: region.defaultTimeZone,
            StartTime: {
              ampm: 'pm',
              hours: 12,
              minutes: '00'
            },
            EndTime: {
              ampm: 'pm',
              hours: 12,
              minutes: 30
            },
            Repeat: 'custom_recurrence',
            RepeatInterval: 1,
            RepeatType: 'week',
            RepeatEndType: 'date',
            EventTags: [],
          }
        },
        staffAndChapter: {
          data: {
            primaryStaff: user.Staff,
            chapter: user.Chapter ? user.Chapter : null,
            staff: [],
            teens: []
          }
        },
        thumbnailAndDescription: {
          data: {
            thumbnail: jsuThumbnail
          }
        },
        options: {
          data: {
            sendPushNotification: true,
            cost: 0
          }
        }
      };
    case 'LNL':
      return {
        details: {
          data: {
            TimeZone: region.defaultTimeZone,
            StartTime: {
              ampm: 'pm',
              hours: 12,
              minutes: '00'
            },
            EndTime: {
              ampm: 'pm',
              hours: 12,
              minutes: 30
            },
            Repeat: 'custom_recurrence',
            RepeatInterval: 1,
            RepeatType: 'week',
            RepeatEndType: 'date',
            EventTags: [],
          }
        },
        school: {},
        staffAndChapter: {
          data: {
            primaryStaff: user.Staff,
            chapter: user.Chapter ? user.Chapter : null,
            staff: [],
            teens: []
          }
        },
        thumbnailAndDescription: {
          data: {
            thumbnail: lnlThumbnail
          }
        },
        options: {
          data: {
            sendPushNotification: true,
            cost: 0
          }
        }
      };
    case 'Other':
      return {
        eventSubType: {},
        details: {
          data: {
            TimeZone: region.defaultTimeZone,
            StartTime: {
              ampm: 'pm',
              hours: 12,
              minutes: '00'
            },
            EndTime: {
              ampm: 'pm',
              hours: 12,
              minutes: 30
            },
            Repeat: null,
            RepeatInterval: 1,
            RepeatType: 'week',
            RepeatEndType: 'date',
            EventTags: [],
          }
        },
        school: {},
        staffAndChapter: {
          data: {
            primaryStaff: user.Staff,
            chapter: user.Chapter ? user.Chapter : null,
            staff: [],
            teens: []
          }
        },
        thumbnailAndDescription: {
          data: {
            thumbnail: otherThumbnail
          }
        },
        registrationAndTickets: {
          data: {}
        },
        options: {
          data: {
            sendPushNotification: true,
            cost: 0
          }
        }
      };
    case 'ShabbatHoliday':
      return {
        eventSubType: {},
        staffAndChapter: {
          data: {
            primaryStaff: user.Staff,
            chapter: user.Chapter ? user.Chapter : null,
            staff: [],
            teens: []
          }
        },
        details: {
          data: {
            TimeZone: region.defaultTimeZone,
            StartTime: {
              ampm: 'pm',
              hours: 12,
              minutes: '00'
            },
            EndTime: {
              ampm: 'pm',
              hours: 12,
              minutes: 30
            },
            Repeat: null,
            RepeatInterval: 1,
            RepeatType: 'week',
            RepeatEndType: 'date',
            EventTags: [],
          }
        },
        school: {},
        thumbnailAndDescription: {
          data: {
            thumbnail: otherThumbnail
          }
        },
        registrationAndTickets: {
          data: {}
        },
        options: {
          data: {
            sendPushNotification: true,
            cost: 0
          }
        }
      };
    case 'Shabbaton':
      return {
        eventSubType: {},
        staffAndChapter: {
          data: {
            primaryStaff: user.Staff,
            chapter: user.Chapter ? user.Chapter : null,
            staff: [],
            teens: []
          }
        },
        details: {
          data: {
            TimeZone: region.defaultTimeZone,
            StartTime: {
              ampm: 'pm',
              hours: 12,
              minutes: '00'
            },
            EndTime: {
              ampm: 'pm',
              hours: 12,
              minutes: 30
            },
            EventTags: [],
          }
        },
        school: {},
        thumbnailAndDescription: {
          data: {
            thumbnail: shabbatonThumbnail
          }
        },
        registrationAndTickets: {
          data: {}
        },
        options: {
          data: {
            sendPushNotification: true,
            cost: 0
          }
        }
      };
  }
  return null;
}

export default defineComponent({
  props: {
    regionId: { type: Number, required: true },
    region: { type: Object as PropType<Region | null>, default: null },
    user: { type: Object as PropType<AdvisorRegions & { Staff: Me }>, required: true },
    eventSubTypes: { type: Array as PropType<GetEventSubTypes>, required: true }
  },
  setup(props) {
    const { client } = useApolloClient();

    const eventType = ref<string | null>(null);
    const stepData = ref<Steps | null>(null);
    const state = ref<FormState>('idle');
    const eventId = ref<number>(-1);
    const seriesId = ref<number>(-1);
    const computedChapterId = computed(() => {
      if (stepData.value?.options.data?.isTYAEvent) {
        return 414;
      } else if (stepData.value?.options.data?.isBGCubedEvent) {
        return 422;
      } else if (stepData.value?.options.data?.isBMBEvent) {
        return 435;
      }
      return null;
    });

    function onStepData(steps: Steps) {
      stepData.value = steps;
    }

    function resetState() {
      state.value = 'idle';
    }

    async function handleSubmit(event: EventScheduleInput) {
      state.value = 'submitting';
      try {
        let meeting: CreateZoomMeetingMutation['createZoomMeeting'] | null = null;
        if (event.event && event.event.zoomUserId) {
          const eventData: EventInput = event.event;
          let zoomStartDate = eventData.startDate;
          let zoomTimeZone = 'UTC';
          if (getZoomTimeZone(eventData.TimeZone) !== 'UTC') {
            zoomTimeZone = getZoomTimeZone(eventData.TimeZone);
            zoomStartDate = tz(tz(eventData.startDate as string), '%FT%T', zoomTimeZone);
          }
          const meetingInput: CreateZoomMeetingMutationVariables = {
            id: eventData.zoomUserId as string,
            input: {
              start_time: zoomStartDate,
              duration: calculateEventDuration(
                eventData.startDate as string,
                eventData.endDate as string
              ),
              timezone: getZoomTimeZone(eventData.TimeZone),
              topic: eventData.eventName,
              password: eventData.zoomPasscode
            }
          };
          if (event.series && event.series.Repeat) {
            meetingInput.input.type = 8;
            meetingInput.input.start_time = zoomStartDate!.replace(/\.000Z/, '');
            if (event.series.Repeat === 'custom_recurrence') {
              meetingInput.input.recurrence = {
                ...event.RepeatConfig
              };
              if (!event.RepeatConfig!.end_times) {
                meetingInput.input.recurrence.end_date_time = event.series.EndDate.replace(
                  /0\.000Z/,
                  '1Z'
                );
              }
            }
            if (event.series.Repeat === 'custom_dates') {
              meetingInput.input.recurrence = {
                repeat_interval: 1,
                type: RecurrenceType.Daily,
                end_times: event.dates!.length || 0
              };
            }
          }
          const result = await client.mutate<CreateZoomMeetingMutation>({
            mutation: CreateZoomMeetingDocument,
            variables: meetingInput
          });
          meeting = (result.data && result.data.createZoomMeeting) || null;
          if (
            meeting &&
            meeting.id &&
            event.series &&
            event.series.Repeat === 'custom_dates' &&
            event.dates!.length > 0
          ) {
            for (let index = event.dates!.length - 1; index >= 0; index--) {
              await client.mutate<UpdateZoomMeetingMutation>({
                mutation: UpdateZoomMeetingDocument,
                variables: {
                  id: meeting!.id,
                  input: {
                    start_time: event.dates![index]!.start,
                    duration: calculateEventDuration(
                      event.dates![index]!.start,
                      event.dates![index]!.end
                    ),
                    occurrence_id: event.dates![index]!.zoomOccurrencyId
                  }
                }
              });
            }
          }
        }
        const isFutureEvent =
          new Date(event.event!.startDate as string).getTime() > new Date().getTime();
        if (!isFutureEvent && event.event) {
          event.event.TeensParticipating = null;
        }
        // we do this here to make life easier for staff who maybe hit the toggle by mistake
        if (event.event && !event.event.registrationRequired) {
          event.event.Tickets = [];
        }
        if (event.series) {
          const result = await client.mutate<ScheduleQueryMutation>({
            mutation: ScheduleQueryDocument,
            variables: {
              series: event.series,
              dates: event.dates,
              event: {
                ...event.event,
                regionId: props.regionId,
                chapterID: computedChapterId.value ? computedChapterId.value : event.event?.chapterID,
                zoomMeetingId: (meeting && meeting.id && meeting.id.toString()) || null,
                zoomPasscodeHashed:
                  (meeting &&
                    meeting.encrypted_password &&
                    meeting.encrypted_password.toString()) ||
                  null
              }
            } as ScheduleQueryMutationVariables
          });

          if (result.data!.scheduleSeries) {
            const upcomingEvents = (result.data!.scheduleSeries as Series).Events.filter(
              (x) => new Date(x.startDate) > new Date()
            );
            if (upcomingEvents.length) {
              upcomingEvents.sort((a, b) => {
                const dateA = new Date(a.startDate);
                const dateB = new Date(b.startDate);
                return +dateA - +dateB;
              });
            }
            eventId.value =
              (upcomingEvents && upcomingEvents.length && upcomingEvents[0].eventId) || -1;
            seriesId.value = (result.data!.scheduleSeries as Series)!.seriesID;
          }
        } else {
          const result = await client.mutate({
            mutation: ScheduleEventDocument,
            variables: {
              event: {
                ...event.event,
                regionId: props.regionId,
                chapterID: computedChapterId.value ? computedChapterId.value : event.event?.chapterID,
                zoomMeetingId: (meeting && meeting.id && meeting.id.toString()) || null,
                zoomPasscodeHashed:
                  (meeting &&
                    meeting.encrypted_password &&
                    meeting.encrypted_password.toString()) ||
                  null
              }
            } as ScheduleEventMutationVariables
          });

          if (result.data && result.data.scheduleEvent) {
            eventId.value = (result.data.scheduleEvent as Event).eventId;
            seriesId.value = (result.data.scheduleEvent as Event).seriesId || -1;
          }
        }
        const notificationInput: SendPushNotificationMutationVariables = {
          input: {
            application: 'ncsy',
            regionId: props.regionId,
            title: 'Event added',
            body: `New event ${event.event!.eventName} added`,
            additionalData: {
              notificationType: 'EventAdded',
              eventId: eventId.value
            }
          }
        };
        if (event.event!.chapterID) {
          notificationInput.input.chapterId = event.event!.chapterID;
        }

        const isSendPushNotification =
          isFutureEvent && stepData.value && stepData.value.options!.data!.sendPushNotification;

        if (!event.event!.internal && isSendPushNotification) {
          const notification = await client.mutate<SendPushNotificationMutation>({
            mutation: SendPushNotificationDocument,
            variables: notificationInput
          });
        }
      } catch (e) {
        alert('There was an error. Please try again.');
        resetState();
        throw e;
      }
      eventType.value = null;
      state.value = 'submitted';
    }

    watch(eventType, () => {
      if (eventType.value && props.region) {
        stepData.value = getDefaultStepData(eventType.value, props.user, props.region);
      }
    });

    return () =>
      h(ScheduleEvent, {
        props: {
          stepData: stepData.value,
          eventType: eventType.value,
          eventSubTypes: props.eventSubTypes,
          state: state.value,
          region: props.region,
          eventId: eventId.value,
          seriesId: seriesId.value
        },
        on: {
          eventType: (type: string) => {
            eventType.value = type;
          },
          stepData: onStepData,
          submit: handleSubmit,
          state: resetState
        }
      });
  }
});
